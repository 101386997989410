import { z } from 'zod';
import { Applications } from '../db-entries/application.js';
import { SamlApplicationConfigs } from '../db-entries/saml-application-config.js';
import { SamlApplicationSecrets } from '../db-entries/saml-application-secret.js';
import { applicationCreateGuard, applicationPatchGuard } from './application.js';
const samlAppConfigGuard = SamlApplicationConfigs.guard.pick({
    attributeMapping: true,
    entityId: true,
    acsUrl: true,
});
export const samlApplicationCreateGuard = applicationCreateGuard
    .pick({
    name: true,
    description: true,
    customData: true,
})
    .extend({
    // The reason for encapsulating attributeMapping and spMetadata into an object within the config field is that you cannot provide only one of `attributeMapping` or `spMetadata`. Due to the structure of the `saml_application_configs` table, both must be not null.
    config: samlAppConfigGuard.partial().optional(),
});
export const samlApplicationPatchGuard = applicationPatchGuard
    .pick({
    name: true,
    description: true,
    customData: true,
})
    .extend({
    // The reason for encapsulating attributeMapping and spMetadata into an object within the config field is that you cannot provide only one of `attributeMapping` or `spMetadata`. Due to the structure of the `saml_application_configs` table, both must be not null.
    config: samlAppConfigGuard.partial().optional(),
});
// Make sure the `privateKey` is not exposed in the response.
export const samlApplicationSecretResponseGuard = SamlApplicationSecrets.guard.omit({
    tenantId: true,
    applicationId: true,
    privateKey: true,
});
export const samlApplicationResponseGuard = Applications.guard.merge(
// Partial to allow the optional fields to be omitted in the response.
// When starting to create a SAML application, SAML configuration is optional, which can lead to the absence of SAML configuration.
samlAppConfigGuard);
const fingerprintFormatGuard = z.object({
    formatted: z.string(),
    unformatted: z.string(),
});
export const certificateFingerprintsGuard = z.object({
    sha256: fingerprintFormatGuard,
});
